import { useRootLoaderData } from '@storefront/ui-components/hooks/useRootLoaderData';

export const useRegion = () => {
  const data = useRootLoaderData();

  const storefrontRegion = data?.regions.find((region) => region.id === data.storefrontSettings?.default_region_id);

  if (storefrontRegion) return { region: storefrontRegion };

  const region = data?.regions.filter((region) => region.currency_code === 'cad')[0];

  if (!region) {
    throw new Error('No region found');
  }

  return { region };
};
